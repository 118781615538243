import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        list: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('sales', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        show: (cntx, { id }) => {
            axios.get(`sales/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
        },
        showSaleNumber: (cntx, { id }) => {
            axios.get(`markets/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
        },
        create: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post('sales', params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        update: (cntx, params) => {
            console.log(params)
            return new Promise((resolve, reject) => {
                axios.put(`sales/${params.id}`, params.data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        updateStack: (cntx, params) => {
            console.log(params)
            return new Promise((resolve, reject) => {
                axios.put(`associate_grades/${params.id}`, params.data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        remove: (cntx, id) => {
            return new Promise((resolve, reject) => {
                axios.delete(`associate_grades/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        levels: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('levels', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        locations: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('locations', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        seasons: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('seasons', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        buyers: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('buyers', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        grades: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('groups', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        getWarehouse: (cntx, params) => {
            console.log(params)
            return new Promise((resolve, reject) => {
                axios.get('warehouses', { params: {
                    "location_id": params.location_id
                }} )
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        getStack: (cntx, params) => {
            console.log(params)
            return new Promise((resolve, reject) => {
                axios.get('warehouses', { params: {
                    "warehouse_id": params.warehouse_id
                }} )
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        societies: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('societies', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        transporters: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('transporters', { params: params })
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        }
    }
}
